<div class="table-container">



  <div class="flex flex-col md:flex-row justify-between pl-4 pr-4 pt-4">
    <h2 class="mb-2">{{ "HEADER.DATA_MANAGEMENT.workers" | translate }}</h2>
    <div class="flex justify-end pl-4 pr-4 pb-4">
      <button class="btn btn-primary ml-2 mr-2 mb-2" (click)="createDrivers()"> {{
        "DRIVERS.actions.add_worker" | translate }} </button>
      <!-- <button class="btn btn-default ml-2 mr-2" (click)="clearFilter()"><i class='bx bx-revision'></i></button>
      <button class="btn btn-default" [class.btn-open-filter]="isFilter" (click)="openFilter()">
        <i class='bx bx-filter-alt'></i>
      </button> -->
    </div>

  </div>



  <!-- <div class="filter open-filter flex flex-col md:flex-row items-start md:items-center flex-wrap "
    [class.open-filter]="isFilter">



  </div> -->




  <app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
  </app-pagination>



  <div class="component-loader" (errorCallback)="getDrivers()" [errorMessage]="'lOADER.errorMessage' | translate "
    component-loader [componentError]="loadingError" [loadingMessage]="'lOADER.loadingMessage' | translate"
    [componentLoading]="loading">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            {{ "DRIVERS.extras.name" | translate }}
          </th>


          <th scope="col">
            {{ "DRIVERS.extras.phone" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.email" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.actions.station_work_at" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.actions" | translate }}
          </th>
          <th scope="col">
            {{ "DRIVERS.extras.active" | translate }}
          </th>

        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of workers | paginate: paginationConfig">
          <td class="pointer weight-label" (click)="goToDetails(item.id)">
            {{ item?.name }}
          </td>


          <td>
            {{ item?.phone }}
          </td>

          <td>
            {{ item?.email }}
          </td>

          <td>
            {{ item?.petrol_station_name }}
          </td>

          <td class="pointer">
            <i class='bx bx-edit-alt edit pointer' (click)="update(item)" id="update"></i>

          </td>
          <td>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="list-{{item.id}}" [ngModel]="!item?.active"
                (change)="releaseToDrivers(item.id, !$event.target['checked'])">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-pagination [paginationData]="paginationConfig" (change)="onPageChangeMarketPlaces($event)">
</app-pagination>
