import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DriversService } from 'src/app/services/drivers.service';

@Component({
  selector: 'app-assign-driver',
  templateUrl: './assign-driver.component.html',
  styleUrls: ['./assign-driver.component.scss']
})
export class AssignDriverComponent implements OnInit {


  drivers: any;
  truckForm: any = this.fb.group({
    user_ids: ['', Validators.required],
  });

  constructor(private dialogRef: MatDialogRef<AssignDriverComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private driversService: DriversService,
    private fb: FormBuilder,
    public translate: TranslateService,) {

  }



  closeModal() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getStationWorkers()
    if (this.data != null) {
      this.truckForm.patchValue({
        user_ids: this.data.petrol_station?.user_ids
      })
    }

  }
  getStationWorkers() {
    const params = {
      all: true, not_working: true,
    }
    this.driversService.getStationWorkers(params).subscribe((res: any) => {
      this.drivers = res.data.items
    }, (error) => { })
  }





  assignDriver() {
    if (this.truckForm.valid) {
      let data = {
        petrol_station: {
          ...this.truckForm.value
        }
      }

      this.dialogRef.close(data);
    }
  }


}
