<div class="modal-wrapper">
  <i class='bx bx-x pointer text-2xl' (click)="closeModal()"></i>
  <div class="modal-header">
    <p class="w-full text-center">
      <ng-container *ngIf="data == null">
        {{ "DRIVERS.actions.create_new_Truck"| translate }}
      </ng-container>
      <ng-container *ngIf="data != null">
        {{ "DRIVERS.actions.edit_Truck" | translate }}
      </ng-container>

    </p>
  </div>
  <div class="modal-content">
    <div>
      <div class="modal-body">

        <form [formGroup]="truckForm">
          <div class="row mb-2">

            <div class="col-md-6" [class.has-error]="!truckForm.get('number').valid && isSubmit">
              <div class="form-group">
                {{ "DRIVERS.extras.number_ride" | translate }}
                <input class="form-control" type="text" formControlName="number" />
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                {{ "DRIVERS.extras.balance" | translate }}
                <input class="form-control" type="text" formControlName="balance" />
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group">
                {{ "DRIVERS.extras.color" | translate }}
                <input class="form-control" type="text" formControlName="color" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                {{ "DRIVERS.extras.year" | translate }}
                <input class="form-control" type="text" formControlName="year" />
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group">
                {{ "DRIVERS.extras.company" | translate }}
                <input class="form-control" type="text" formControlName="manufacturer" />
              </div>
            </div>

            <div class="col-md-6" [class.has-error]="!truckForm.get('model').valid && isSubmit">
              <div class="form-group">
                {{ "DRIVERS.extras.model" | translate }}
                <input class="form-control" type="text" formControlName="model" />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                {{ "DRIVERS.extras.weight" | translate }}
                <input class="form-control" type="text" formControlName="weight" />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                {{ "DRIVERS.extras.capacity" | translate }}
                <input class="form-control" type="text" formControlName="capacity" />
              </div>
            </div>




            <div class="col-md-6" [class.has-error]="!truckForm.get('license_number').valid && isSubmit">
              <div class="form-group">
                {{ "DRIVERS.extras.license_number" | translate }}
                <input class="form-control" type="text" formControlName="license_number" />
              </div>
            </div>



            <div class="col-md-6" [class.has-error]="!truckForm.get('license_issue_date').valid && isSubmit">
              <div class="form-group">
                <label class="mb-2"> {{ "DRIVERS.extras.license_issue_date" | translate }}</label>
                <div class="form-group relative">
                  <input [matDatepicker]="pickId" name="started-date" class="form-control h-10 date"
                    placeholder="{{ 'DRIVERS.extras.license_issue_date' | translate }}" [max]="max"
                    (dateInput)="setDateBox($event,'startDate')" formControlName="license_issue_date" />
                  <mat-datepicker #pickId></mat-datepicker>
                  <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId">
                  </mat-datepicker-toggle>

                </div>
              </div>
            </div>

            <div class="col-md-6" [class.has-error]="!truckForm.get('license_expiration_date').valid && isSubmit">
              <div class="form-group">
                <label class="mb-2"> {{ "DRIVERS.extras.license_expiration_date" | translate }}</label>
                <div class="form-group relative">
                  <input [matDatepicker]="pickId2" name="started-date" class="form-control h-10 date"
                    (dateInput)="setDateBox($event,'endDate')"
                    placeholder="{{ 'DRIVERS.extras.license_expiration_date' | translate }}" [min]="min"
                    formControlName="license_expiration_date" />
                  <mat-datepicker #pickId2></mat-datepicker>
                  <mat-datepicker-toggle class="input-icon date-picker absolute top-0 w-full" [for]="pickId2">
                  </mat-datepicker-toggle>

                </div>
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group file">
                <label class="mb-2"> {{ "DRIVERS.extras.front" | translate }}</label>
                <div class="input-group mb-3">
                  <input class="form-control " accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelected($event)"
                    type="file" />
                </div>

              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group file">
                <label class="mb-2"> {{ "DRIVERS.extras.back" | translate }}</label>
                <div class="input-group mb-3">
                  <input class="form-control " accept=".png, .jpg, .jpeg, .gif" (change)="OnFileSelected2($event)"
                    type="file" />
                </div>

              </div>
            </div>





          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="createTruck()">
        <ng-container *ngIf="data == null">
          {{ "DRIVERS.actions.create_new_Truck" | translate }}
        </ng-container>
        <ng-container *ngIf="data != null">
          {{ "DRIVERS.actions.edit_Truck" | translate }}
        </ng-container>
      </button>

      <button class="btn btn-default mb-2" (click)="closeModal()"> {{ "COMMON.cancel" | translate }}</button>
    </div>
  </div>
</div>
